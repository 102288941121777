import React from 'react';
import { Col, Row } from 'react-bootstrap';
import EventInfoLine, {TYPE_EVENT_INFO_LINE_LOCATION, TYPE_EVENT_INFO_LINE_DATE} from 'components/EventInfo';
import categories from '../../../../useSectionedEventsList/categories';
import { useTranslation } from 'react-i18next';
import Purchases from './components/Purchases';
import Actions from './components/Actions';
import Tickets from './components/Tickets';
import Logotype from 'components/Logotype';
import FORMAT from 'config/date/format';
import moment from 'moment';


export default ({ item, type }) => {

    const { t } = useTranslation('tickets');

    const { ticket_reservations: tickets, merchandise_reservations_groups: purchases } = item;

    return (
        <div className='zt-tickets__group__event'>
            <Row>
                <Col xs md={8}>
                    <h2 >{item.name}</h2>
                    <div className='zt-p1 mb-4'>{item.performer_name}</div>
                    <EventInfoLine type={TYPE_EVENT_INFO_LINE_LOCATION} card>{item?.map?.venue?.name || '--'}</EventInfoLine>
                    <EventInfoLine type={TYPE_EVENT_INFO_LINE_DATE} card>{t('date', {date: moment(item.start_date, FORMAT)})}</EventInfoLine>  
                    <Tickets {...{tickets}} />
                    {purchases && <Purchases {...{event_id: item?.id, purchases}} />}
                    {type !== categories.ARCHIVED && <Actions {...{event_id: item?.id, tickets}} />}
                </Col>
                <Col className='d-none d-md-block' md={4}>
                    <div className="float-right">
                        <div className='zt-webshop__event__media'>
                            {item.thumbnail ?
                            <img alt={item.name} className='zt-webshop__event__media__image' src={`${item.thumbnail}`} />
                            :
                            <Logotype className='zt-webshop__event__media__logotype'/>
                            }    
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}