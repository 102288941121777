import Banner from 'components/Header/Banner';
import Error from 'pages/Event/components/Error';
import Loading from 'pages/WebShop/pages/List/components/Loading';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import * as actions from './../../pages/List/store/actions';
import CheckoutButton from './components/CheckoutButton';
import * as sellModes from './store/sellMode';
import Form from './components/Form';
import Info from './components/Info';
import Total from './components/Total';
import './styles.scss';
import useValidate from './store/validate/useValidate';
import useProceedSale from './store/sell/useProceedSale';
import { SELLTOANYONE, SELLTOFRIEND } from './store/sell/sellToModes';
import RequestSent from './components/RequestSent';
import Restricted from './components/Restricted';

export const path = '/sell/:event_id/:ticket_id?';
export const link = (event_id, ticket_id = null) => path.replace(':event_id', event_id).replace(':ticket_id', ticket_id || '');


const Sell = props => {

    const { event_id, ticket_id=null } = useParams();

    const sellMode = +ticket_id !== 0 ? sellModes.SELL_TICKET : sellModes.SELL_ALL;

    const { state: { loading, validated, error, restricted, targetModes, merch, tickets }, validate, kids } = useValidate({mode: sellMode, eventId: event_id, ticketId: ticket_id});
    const { state: saleState, proceedSale, setTargetMode, setKidsFriend, setFriend } = useProceedSale(sellMode, event_id, ticket_id);

    const { events, loading: ticketsLoading, error: ticketsError } = useSelector(state => state.tickets);
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(actions.getTickets());
    }

    const event = events?.find(event => event.id === +event_id);

    useEffect(() => {
        if (!events) {
            refresh();
            return;
        }
        validate();
    }, [events]);


    const valid = !ticketsLoading && !loading && !ticketsError && !error && (saleState.mode === SELLTOANYONE || (saleState.mode === SELLTOFRIEND && +saleState.friendId));

    if (saleState.done) {
       return <RequestSent />
    }
    
    if (ticketsLoading || loading) {
        return <Loading />
    }
    
    if (error || restricted) {
        return <Restricted {...{error, tickets}} />
    }

    if (ticketsError || !event) {
        return <Container>
                <Row>
                    <Col xs lg="9">
                        <Error retry={() => refresh()} />
                    </Col>
                </Row>
            </Container>
    }    

    return (<>
        <Info {...{event, ticket_id, tickets}} />
        <Form {...{mode: saleState.mode, onSelectMode: setTargetMode, targetModes, setFriend, eventId: event_id}} />
        <Total total={targetModes[saleState.mode]} />
        <CheckoutButton {...{valid, loading: saleState.loading, onPress: () => proceedSale()}} />
        </>
    );
}

export default props => {
    const { t } = useTranslation('tickets');

    return  (<CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-tickets'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="9">
                        <h4 className='zt-tickets__title'>{t('sell.label', 'Sell ticket to a friend')}</h4>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs lg="9">
                        <Sell {...props} />
                    </Col>
                </Row>
            </Container>
            <Banner />
        </div>
    </CSSTransition>
    );
}