import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Feed from './components/Feed';
import './style.scss';

export const path = '/tickets';
export const link = path;

export default props => {

    const { t } = useTranslation('tickets');

    return  (<CSSTransition in={true} appear={true} timeout={200} classNames="zt-page__transition">
        <div className='zt-tickets'>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="9">
                        <h4 className='zt-tickets__title'>{t('label', 'My Tickets')}</h4>
                    </Col>
                </Row>
            </Container>
            <Feed />
        </div>
    </CSSTransition>
    );
}