import * as actions from './constants';
import * as sectionActions from './../store/constants';
import adoptStats from '../adoptStats';


const defaultSeats = {
    map: null, //String <svg... 
    data: null,
    general_admission: false,
    matrix: {},
}

const defaultState = {
    section: null, //Current sections, can not be null
    seats: defaultSeats,
    prices: null,
    loading: false,
    error: null
}

export default function(state = defaultState, action) {
    const { type, payload=null, error=null } = action;
    
    switch (type) {

        case sectionActions.EVENTBUY_SELECT_SECTION:
        case actions.RESET_SEATS: {
            return defaultState;
        }

        case actions.SET_SEATSMAP_MATRIX: {
            return {
                ...state,
                seats: {
                    ...state.seats,
                    matrix: {...payload}
                }
            }
        }

        case actions.FETCH_SEATSMAP_START: {
            return {
                ...state,
                section: payload,
                seats: defaultSeats,
                loading: true
            }
        }

        case actions.FETCH_SEATSMAP_SUCCESS: {

            const { map, stats, rows, general_admission = false } = payload;

            const { prices } = adoptStats(stats);

            const data = {};

            rows.forEach(row => {
                const seats = row?.seats;
                if (!data[row.name]) {
                    data[row.name] = {}
                }
                seats.forEach(seat => data[row.name][seat.name] = seat.prices?.[0]?.actual_price)
            });

            const info = {
                ...defaultSeats,
                map,
                data,
                general_admission
            };

            return {
                ...state,
                prices: prices?.sort((a, b) => a - b).reverse(), //Decrement sorting (5040, 3050, 2080 ...)
                seats: info,
                loading: false,
                error: null
            }
        }

        case actions.FETCH_SEATSMAP_FAILURE: {
            return {
                ...defaultState,
                error
            }
        }

        default:
            return state;
    }
}