import React from 'react';
import InfoLine from '../Tickets/components/InfoLine';
import { WebShop as WebShopIcon } from 'components/Icons/Glyphs';
import { sellItemLink } from 'pages/MyTickets';
import { useTranslation } from 'react-i18next';

const Merch = ({ event_id, item: { id, merchandise_set: merchandise, quantity, merchandise_option: option=null }}) => {

    const goToSellLink = (item_id = null) => '#';//sellItemLink(event_id, item_id);

    return <a href={`${goToSellLink(id)}`} title='Sell item'>{`${merchandise?.name}${option ? ` [${option?.name}]` : ``} (${quantity})`}</a>;
}

export default ({ event_id, purchases: items }) => {

    const { t } = useTranslation('tickets')

    return <div className='zt-tickets__group__event__purchases'>
        <InfoLine>
            <WebShopIcon className='event-info-line__icon' />
            {items?.length > 0 && items.map((item, key) => <Merch {...{item, key, event_id}} />).reduce((prev, curr) => [prev, ', ', curr])}
            {items?.length > 1 && (<>{` `}<a href={sellItemLink(event_id)}>{t('sell_item.all.button', '(Return all)')}</a></>)}
        </InfoLine>
    </div>
}