import React, { useState } from 'react';
import { WebShop as WebShopIcon, Refund as RefundIcon } from 'components/Icons/Glyphs';
import { PrimaryButton, SecondaryButton } from 'components/buttons/Button';
import { path as webshop_url } from 'pages/WebShop/pages/List';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Modal } from 'react-bootstrap';
import ChooseTickets from './components/ChooseTickets';
import { sellLink } from 'pages/MyTickets';

export default ({ event_id, tickets }) => {

    const { t } = useTranslation('tickets');
    const history = useHistory();

    const [showChooser, setShowChooser] = useState(false);
    const goToSell = (ticket_id = null) => history.push(sellLink(event_id, ticket_id));

    return <div className='zt-tickets__group__event__actions'>
        <PrimaryButton onClick={() => history.push(webshop_url)} icon={WebShopIcon}>{t('feed.actions.webshop', 'Webshop')}</PrimaryButton>
        { tickets?.length === 1 ?
        <><SecondaryButton icon={RefundIcon} onClick={() => goToSell()}>{t('feed.actions.sell', 'Sell ticket')}</SecondaryButton></>
        :
        <>
            <SecondaryButton icon={RefundIcon} onClick={() => setShowChooser(true)}>{t('feed.actions.sell_single', 'Sell single ticket')}</SecondaryButton>
            <SecondaryButton icon={RefundIcon} onClick={() => goToSell()}>{t('feed.actions.sell_all', 'Sell all tickets')}</SecondaryButton>
            <Modal show={showChooser} size="lg" centered className='zt-modal-centered'>
                <ChooseTickets {...{t: (label, defaultValue) => t(`feed.actions.choose_ticket.${label}`, defaultValue), tickets, onSelect: ticket => goToSell(ticket), onClose: () => setShowChooser(false)}} />
            </Modal>
        </>
        }
    </div>;
}