import React, { useEffect, useState } from 'react';
import MiniLoader from 'components/Header/Filter/City/MiniLoader';
import { PrimaryButton } from 'components/buttons/Button';
import { ArrowRight, QR } from 'components/Icons/Glyphs';
import { useSelector, useDispatch } from 'react-redux';
import { link as tickets_url } from 'pages/MyTickets';
import useGetReservation from './useGetReservation';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Error from '../../../components/Error';
import useGetPayment from './useGetPayment';
import { useParams } from 'react-router';
import './style.scss';

export default props => {

    const [t] = useTranslation('buying');
    const tt = (label, def='') => t(`confirmation.${label}`, def);
    const [initilased, setInitalised] = useState(false);
    const history = useHistory();

    const { state: { loading, error, payment  }, get } = useGetPayment();

    const { payment_id } = useParams();

    useEffect(() => {
        get(payment_id);
        setInitalised(true);
    }, [payment_id]);

    if (!initilased) {
        return false;
    }

    if (error) {
        return <Error retry={() => get(payment_id)}/>
    }

    if (loading) {
        return <MiniLoader />
    }

    const { primary_reservation_id: reservation_id } = payment;

    return <div className='zt-event-buy-confirm'>
       <h4 className='bold zt-event-buy-confirm__header'>{tt('header', 'Thank you for your purchare!')}</h4> 
       <div className='zt-p1 zt-event-buy-confirm__info'>{tt('orderinfo', {id: reservation_id}, `Your reservation ID ${reservation_id}.`)}<br/>
       {tt('emailinfo', 'You will receive a purchase order confirmation email shortly!')}</div>
       <div className='zt-p1 zt-event-buy-confirm__info zt-p1 zt-event-buy-confirm__info--app'><b>{tt('gotoapp', 'Please register in the app to generate the tickets!')}</b></div>       
       <PrimaryButton onClick={() => history.push(tickets_url)} className='zt-event-buy-confirm__gotobutton' icon={QR}>{tt('buttons.gotomytickets', 'Goto My Tickets')}  <ArrowRight className='zt-event-buy-confirm__gotobutton__arrow'/></PrimaryButton>
    </div>
}